$(document).ready(function() {
	$('.js-arrow').click(function(e){
		e.stopPropagation();
		$(this).closest('.list').toggleClass('hover').siblings().removeClass('hover');
		$('.list .list').removeClass('hover');
	});

	if(('.js-btn-showsubfilter').length){
		$('.js-btn-showsubfilter').click(function(){
			$(this).toggleClass('active').closest('.hassub').find('.sub').toggleClass('active');
		});

		$('.js-btn-showsubfilter2').click(function(){
			$(this).toggleClass('active').closest('.has2sub').find('.sub2').toggleClass('active');
		});

		$('.js-dbAsideTrigger').on('click', function(e) {
			// e.stopPropagation();
			$('.js-dbAside').addClass('opened');
			$('body').addClass('nav-opened')
		});
	};

	$('.js-arrow-child').click(function(e){
		e.stopPropagation();
		$(this).closest('.list .list').toggleClass('hover');
	});

	$('.js-showSearch').click(function(e){
		e.stopPropagation();
		$(this).closest('.search').toggleClass('active');
		if($('.search').hasClass('active')){
			$('.first').removeClass('fa-search').addClass("fa-times");
		}
		// if($('.search')){
		else{
			$('.first').removeClass("fa-times").addClass('fa-search');
		}
	});

	$('.js-navTrigger').on('click', function(e) {
		e.stopPropagation();
		$('.js-nav').toggleClass('nav-opened');
		$('body').toggleClass('nav-opened')
	});

	// $('.js-langActive').click(function() {
	// 	$(this).addClass('active').siblings().removeClass('active');
	// })

	// ACTIVE MENU
	// var pgurl = window.location.href.substr(window.location.href.lastIndexOf("/")+1);
	var pgurl = window.location.href.split('/').pop();
	if (pgurl=="") {
		$('.navigation-list >.list#item-index').addClass('active');
	}
	
	$(".navigation-list >.list >a").each(function(){
		if($(this).attr("href") == pgurl)
			$(this).closest('.list').addClass("active");
	});

	$('.js-item_nav-show').click(function(){
		$(".navigation__menu .item_nav").removeClass('active').find(".js-item_nav-show").html("+");
		$(this).closest(".item_nav").addClass('active');
		$(this).html("-");
	});

	$('.js-nav-trigger').on('click', function() {
		$('.nav-wrapper').toggleClass('nav-opened');
		$('body').toggleClass('nav-opened')
	});

	$('.js-openMenuChild').click(function(e){
		e.stopPropagation();
		$(this).closest('.item').toggleClass('hover').siblings().removeClass('hover');
	});

	if(('.js-dropdownBtn').length){
		$('.js-dropdownBtn').click(function(e){
			e.stopPropagation();
			$(this).closest('.dropdown').toggleClass('active');
		});
	}

	$('.back2top').on('click', function() {
		$('body, html').animate({scrollTop: 0}, 800)
	});
	
	if($('.js-slick-1').length > 0){
		$('.js-slick-1').slick({
		    infinite: true,
		    autoplay: true,
		    autoplaySpeed: 3000,
		    dots: true,
		    arrows: true,
		});
	}

	// if($('.js-slick-1-faded').length){
	// 	$('.js-slick-1-faded').slick({
	// 	    infinite: true,
	// 	    autoplay: true,
	// 	    autoplaySpeed: 3000,
	// 	    fade: true,
	// 	    dots: true,
	// 	    arrows: true,
	// 	});
	// }

	// if($('.js-slick-1-center').length){
	// 	$('.js-slick-1-center').slick({
	// 	    infinite: true,
	// 	    autoplay: false,
	// 	    autoplaySpeed: 3000,
	// 	    arrows: true,
	// 	    centerMode: true
	// 	});
	// }

	if ($('.js-slick-3').length) {
		$('.js-slick-3').slick({
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: true,
			dots: true,
			infinite: true,

			responsive: [
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 360,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}

	if ($('.js-slick-4').length > 0) {
		$('.js-slick-4').slick({
			slidesToShow: 4,
			slidesToScroll: 4,
			arrows: true,
			infinite: false,

			responsive: [
				// {
				// 	breakpoint: 992,
				// 	settings: {
				// 		slidesToShow: 4,
				// 		slidesToScroll: 4
				// 	}
				// },
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
			]
		});
	}

	$('.slider-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.slider-nav'
	});
	$('.slider-nav').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.slider-for',
		dots: false,
		// centerMode: true,
		focusOnSelect: true
	});

})
.mousedown(function(e){
    var blur_off = $('.js-blurOff');
    // if(!blur_off.is(e.target)){
    if(blur_off.has(e.target).length === 0){
    	blur_off.removeClass('open');
    	$('body').removeClass('nav-opened aside-opened');
    	$('.js-nav').removeClass('nav-opened aside-opened');
		$('.navigation .list').removeClass('hover');
		$('.js-dbAside').removeClass('opened');
		// $('.search').removeClass('active')
    }
});

$(window).on('scroll', function(){
	var scrollTop = $(this).scrollTop();
	var win_h = $(this).height();

	// if($('.js-loadAni').length){
	// 	// console.log(scrollTop);
	// 	var i = $('.js-loadAni');

	// 	i.each(function(){
	// 		// console.log(i_height);
	// 		var i_height = $(this).offset().top;

	// 		if(scrollTop >= i_height-(win_h*2/3)){ 
	// 			// console.log('right');
	// 			$(this).addClass('loadAni');
	// 		}
	// 		// else{
	// 		// 	i.removeClass('loadAni')
	// 		// }
	// 	});
	// }

	// var header_top = $('.header__top').height(),
	// 		header_mid = $('.header__mid').height(),
	// 		nav = $('.navigation'),
	// 		navTop = header_top + header_mid;

	if(scrollTop >= (win_h / 2)) {
		$('.back2top').addClass('active');
	}
	else {
		$('.back2top').removeClass('active');
	}

	// if(scrollTop >= navTop){
	// 	nav.addClass('fixed');
	// }
	// else{
	// 	nav.removeClass('fixed');
	// }
});